import React, { useEffect } from "react"
import PropTypes from "prop-types"

const AddressFinderInput = ({
  id,
  name,
  placeholder,
  onAddressSelected,
  apiKey,
  country,
}) => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://api.addressfinder.io/assets/v3/widget.js"
    script.async = true

    script.onload = () => {
      if (window.AddressFinder) {
        const widget = new window.AddressFinder.Widget(
          document.getElementById(id),
          apiKey,
          country
        )

        widget.on("result:select", event => {
          if (onAddressSelected) {
            onAddressSelected(event);
          }
        })
      }
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [id, apiKey, country, onAddressSelected])

  return (
    <input
      id={id}
      name={name}
      className="form-control"
      placeholder={placeholder}
    />
  )
}

AddressFinderInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onAddressSelected: PropTypes.func.isRequired,
  apiKey: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
}

AddressFinderInput.defaultProps = {
  placeholder: "Search your address",
}

export default AddressFinderInput
