import axios from "axios"
import React, { useState } from "react"
import SEO from "../components/App/SEO"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Loader from "../components/Index/Loader"
import { aggregatorOptions } from "../utils/constants"
import { Formik, Field, Form, ErrorMessage } from "formik"
import FieldWithError from "../components/Common/FieldWithError"
import AddressFinderInput from "../components/Common/AddressFinderInput"
import { accreditationValidationSchema } from "../utils/FormValidationSchema"

const AccreditationForm = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  // Initial form field values
  const initialValues = {
    companyName: "",
    abn: "",
    aggregator: "",
    profession: "",
    licenceType: "",
    legalFirstName: "",
    lastName: "",
    email: "",
    phone: "",
    mobilePhone: "",
    address: "",
    productInterest: "SMSF - Commercial / Residential",
  }

  // Form submission handler
  const onSubmit = async (values, actions) => {
    setLoading(true)
    try {
      // Send form data to backend
      await axios.post(
        `${process.env.GATSBY_BACKEND_API_URL}/broker/accreditation`,
        values
      )
      setSubmitSuccess(true)
      actions.resetForm()
      window.scrollTo({ top: 0, behavior: "smooth" })
    } catch (error) {
      console.error("Submission error:", error)
      setSubmitSuccess(false)
    } finally {
      setLoading(false)
      actions.setSubmitting(false)
    }
  }

  return (
    <Layout>
      {/* Meta details for SEO */}
      <SEO
        title="Broker Accreditation | Oxygen - SMSF Lending Made Simple"
        description="Join Oxygen for access to fantastic SMSF lending products"
      />
      <Navbar />
      <div className="accreditation-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>Broker Accreditation</h2>
            <h3>Join Oxygen for access to fantastic SMSF lending products.</h3>
          </div>
        </div>
      </div>

      <div className="container form-container accreditation-form-container">
        <section className="form-area">
          {submitSuccess && (
            <div className="alert alert-success">
              Form submitted successfully!
            </div>
          )}

          {loading && <Loader />}

          <Formik
            initialValues={initialValues}
            validationSchema={accreditationValidationSchema}
            onSubmit={onSubmit}
          >
            {({ setFieldValue }) => (
              <Form className="accreditation-form">
                <h3 className="form-section-title mb-3">
                  Your Company Details
                </h3>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    {/* Company Name Field */}
                    <FieldWithError
                      label="Company Name"
                      name="companyName"
                      placeholder="Your broker company name"
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    {/* ABN Field */}
                    <FieldWithError
                      label="ABN (Optional)"
                      name="abn"
                      placeholder="Your company ABN"
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    {/* Profession Dropdown */}
                    <FieldWithError
                      label="Your Profession"
                      name="profession"
                      as="select"
                    >
                      <option value="">Select your profession</option>
                      <option value="Broker">Broker</option>
                      <option value="Accountant">Accountant</option>
                      <option value="Wealth Advisor">Wealth Advisor</option>
                    </FieldWithError>
                  </div>
                  <div className="col-md-6 mt-3">
                    {/* Aggregator Dropdown */}
                    <FieldWithError
                      label="Aggregator (Optional)"
                      name="aggregator"
                      as="select"
                    >
                      {aggregatorOptions.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </FieldWithError>
                  </div>
                </div>

                {/* Licence Type Radios */}
                <div className="form-group mt-3">
                  <label>Licence Type Status</label>
                  <div>
                    {["ACL", "CRN", "AFSL"].map(type => (
                      <label key={type} className="mr-5">
                        <Field type="radio" name="licenceType" value={type} />{" "}
                        {type}
                      </label>
                    ))}
                  </div>
                  <ErrorMessage
                    name="licenceType"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <h3 className="form-section-title mt-5 mb-4">Your Details</h3>

                <div className="row">
                  <div className="col-md-6 mt-3">
                    {/* Legal First Name Field */}
                    <FieldWithError
                      label="Legal First Name"
                      name="legalFirstName"
                      placeholder="Your legal first name"
                    />
                    <div className="sub-label">
                      <span>Required for commissions and reporting</span>
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    {/* Last Name Field */}
                    <FieldWithError
                      label="Last Name"
                      name="lastName"
                      placeholder="Your last name"
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    {/* Email Field */}
                    <FieldWithError
                      label="E-mail Address"
                      name="email"
                      type="email"
                      placeholder="Your email address"
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    {/* Phone Number Field */}
                    <FieldWithError
                      label="Phone Number"
                      name="phone"
                      placeholder="Your phone number"
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    {/* Mobile Phone Number Field */}
                    <FieldWithError
                      label="Mobile Phone Number (Optional)"
                      name="mobilePhone"
                      placeholder="Your mobile phone number"
                    />
                  </div>

                  <div className="col-md-6 mt-3">
                    <label htmlFor="address">Address</label>
                    <AddressFinderInput
                      id="address"
                      name="address"
                      apiKey={process.env.GATSBY_ADDRESSFINDER_API_KEY}
                      country="AU"
                      onAddressSelected={address =>
                        setFieldValue("address", address)
                      }
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-md-6 mt-3">
                    <label htmlFor="address">Product Interest</label>
                    <div></div>
                    <input
                      type="checkbox"
                      id="productInterest"
                      name="productInterest"
                      className="form-check-input custom-checkbox"
                      checked
                      disabled
                    />
                    <label
                      htmlFor="productInterest"
                      className="form-check-label custom-checkbox-label"
                    >
                      SMSF - Commercial / Residential
                    </label>
                  </div>
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className="default-btn primary-btn mt-4 mb-5"
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </section>
      </div>
      <Footer />
    </Layout>
  )
}

export default AccreditationForm
