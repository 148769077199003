import React from "react"
import { Field, ErrorMessage } from "formik"

// Reusable Field Component with Error Message
const FieldWithError = ({ label, name, ...props }) => (
  <div className="form-group">
    <label>{label}</label>
    <Field name={name} className="form-control" {...props} />
    <ErrorMessage name={name} component="div" className="text-danger" />
  </div>
)

export default FieldWithError
